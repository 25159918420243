<template>
    <div>
        <course-create
            v-model="newCourseCreation"
            :course="localCourse"
        >

        </course-create>

        <v-card class="mb-6">
            <v-card-text class="py-12">
                <div
                    class="d-flex flex-column ma-auto"
                    style="width:50%;row-gap:1rem;"
                >
                    <h5 class="text-h5 text-center text-wrap mx-auto"
                        style="width:75%"
                    >
                        Education, talents, and career opportunities.
                        <span class="primary--text"> All in one place.</span>
                    </h5>
                    <p class="text-center text-wrap text-body-1 mx-auto mb-0">
                        Grow your skill with the most reliable trainings and certifications in Insights, programming, and data analysis.
                    </p>
                    <div
                        class="d-flex justify-center align-center"
                        style="column-gap: 1rem;"
                    >
                        <v-text-field
                            v-model="coursesSearch"
                            dense
                            outlined
                            hide-details
                            placeholder="Find your course"
                            style="max-inline-size:400px" 
                            @keydown.enter="searchCourse"
                        >
                            <template v-slot:append-outer>
                                <v-btn
                                    color="primary"
                                    outlined
                                    dense
                                    tile
                                    icon
                                    @click="searchCourse"
                                    style="top: -8px;border-radius:5px"
                                    >
                                    <v-icon>
                                        {{ icons.mdiMagnify }}
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </div>
                    <img
                        src="@/assets/images/academy/academy-course-illustration1.png"
                        class="illustration1 d-none d-md-block"
                        height="180"
                    >
                    <img
                        src="@/assets/images/academy/academy-course-illustration2.png"
                        class="illustration2 d-none d-md-block"
                        height="100"
                    >
                </div>
            </v-card-text>
        </v-card>
        <v-card
            class="mb-6"
        >
            <v-card-text>
                <div class="d-flex justify-space-between align-center flex-wrap mb-6">
                    <div>
                        <h6 class="text-h6">Courses</h6>
                        <div>Total of {{ totalCourses }} courses</div>
                    </div>
                    <!-- <div class="d-flex flex-wrap align-center">
                        <v-combobox
                            v-model="filter"
                            :items="['All Courses', 'Onboarding', 'Qual']"
                            dense
                            outlined
                            hide-details
                            class="mr-4"
                        >

                        </v-combobox>
                        <v-switch
                            v-model="hideCompleted"
                            hide-details
                            :label="`Hide Completed`"
                            class="pt-0 mt-0"
                        ></v-switch>
                    </div> -->
                    <div class="d-flex flex-wrap align-center"
                        v-if="instructorPermission">
                        <v-btn
                            dense
                            color="primary"
                            @click="openCoursePanel()"
                        >
                            + Create Course
                        </v-btn>
                    </div>
                </div>
                <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                        v-for="(course, i) in coursesOptions"
                        :key="i"
                    >
                        <v-card
                            outlined
                        >
                            <div
                                class="pa-2"
                            >
                                <v-img
                                    :src="require(`@/assets/images/academy/${course.img}`)"
                                    v-if="course.img"
                                >
                                </v-img>
                                <v-img
                                    v-else
                                    class="white--text "
                                    :gradient="`to bottom, ${stringToHslColor(course.name)}, ${stringToHslColor(course.name)}`"
                                    height="200px"
                                    >
                                    <v-app-bar
                                        flat
                                        dense
                                        color="rgba(0, 0, 0, 0)"
                                    >

                                        <v-spacer></v-spacer>

                                        <v-btn
                                        color="white"
                                        icon
                                        @click="openCoursePanel(course)"
                                        v-if="instructorPermission"
                                        >
                                            <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                                        </v-btn>
                                    </v-app-bar>
                                    <v-card-title :style="`color:${hsl2rgb(course.name)} !important`"
                                    >{{ course.name }}</v-card-title>
                                </v-img>
                            </div>
                            <v-card-text
                                class="pt-3"
                            >
                                <div
                                    class="d-flex justify-space-between align-center mb-4"
                                >
                                    <v-chip
                                        class="ma-0"
                                        small
                                        :color="resolveCourseLevelVariant(course.level)"
                                    >
                                        {{ course.level }}
                                    </v-chip>
                                </div>
                                <h6 class="text-subtitle-1 font-weight-bold">
                                    {{ course.name }}
                                </h6>
                                <p>
                                    {{ course.desc }}
                                </p>
                                <div
                                    class="d-flex align-center mb-1"
                                >
                                    <v-icon
                                        class="mr-2"
                                    >
                                        {{ icons.mdiClockOutline }}
                                    </v-icon>
                                    <div class="text-body-1 my-auto">{{ formatDuration(course.duration) }}</div>
                                </div>
                                <v-btn
                                    width="100%"
                                    color="primary"
                                    @click="$router.push({name:'academy-course-detail',params:{slug:course.slug}})"
                                >
                                    Course Detail
                                </v-btn>
                            </v-card-text>
                        </v-card>

                    </v-col>
                </v-row>
            </v-card-text>
            
            <infinite-loading 
                ref="infiniteLoading"
                :identifier="infiniteId"
                @infinite="loadItems"
            >
            <div slot="spinner"></div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
            
            </infinite-loading>
        </v-card>
    </div>
</template>

<script>
import { ref, watch, onMounted, onUnmounted, getCurrentInstance } from '@vue/composition-api'
import academyStoreModule from '../academyStoreModule'
import store from '@/store'
import { mdiMagnify, mdiClockOutline, mdiPencilOutline } from '@mdi/js'
import { getInitialName, stringToHslColor, formatDuration } from '@core/utils'
import useCourse from '../useCourse'
import InfiniteLoading from 'vue-infinite-loading'
import CourseCreate from '../course-create/CourseCreate.vue'

export default ({
    components:{
        InfiniteLoading,
        CourseCreate
    },
    setup() {

        const vm = getCurrentInstance().proxy
        const instructorPermission = vm.$ability.can('add', 'academy')
        const ACADEMY_APP_STORE_MODULE_NAME = 'app-academy'
        // Register module
        if (!store.hasModule(ACADEMY_APP_STORE_MODULE_NAME)) store.registerModule(ACADEMY_APP_STORE_MODULE_NAME, academyStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
        if (store.hasModule(ACADEMY_APP_STORE_MODULE_NAME)) store.unregisterModule(ACADEMY_APP_STORE_MODULE_NAME)
        })


        const filter = ref("All Courses")
        const hideCompleted = ref(true)
        const courses = ref([])
        const newCourseCreation = ref(false)

        const localCourse = ref({})
        
        const init = () => {
            localCourse.value = {
            name:"",
            desc:"",
            category:null,
            instructors:[],
            file:null,
            image:null,
            languages:[],
            level:null,
            status:null,
            sections:[]
        }
        }

        const {
            resolveCourseLevelVariant,
            coursesLoading,
            totalCourses,
            coursesOptions,
            coursesSearch,
            courseData,
            infiniteId,
            coursesPage,
            
            fetchCourses,
            fetchCourse
        } = useCourse()

    const openCoursePanel = async (course=null) => {
        if (course) {
            await fetchCourse(course.slug)
            localCourse.value = JSON.parse(JSON.stringify(courseData.value))
            console.log(courseData.value)
        } else {
            init()
        }
        newCourseCreation.value = true

    }

        const hsl2rgb = (str) => {
            const saturation = 55;
            const lightness = 50;
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }

            var h = hash % 360;
            
            let a=saturation*Math.min(lightness,1-lightness);
            let f= (n,k=(n+h/30)%12) => lightness - a*Math.max(Math.min(k-3,9-k,1),-1);
            return 'rgb(' + f(0) + ',' + f(8) + ',' + f(4) + ')';
        }  

        

        const loadItems = async ($state) => {
            await fetchCourses($state)
        }
        
        const searchCourse = async () => {
            
            coursesOptions.value = []
            infiniteId.value += 1
            coursesPage.value = 1
            
        }

        
        

        const manualLoad = () => {
        vm.$nextTick(() => {
            vm.$refs.infiniteLoading.attemptLoad()
        })
        
        }
        


        onMounted(() => {
            init()
        })


        return {
            instructorPermission,
            localCourse,
            openCoursePanel,
            totalCourses,
            coursesOptions,
            coursesSearch,
            manualLoad,
            filter,
            hideCompleted,
            resolveCourseLevelVariant,
            loadItems,
            newCourseCreation,

            searchCourse,
            infiniteId,
            formatDuration,
            stringToHslColor,
            hsl2rgb,

            icons: {
                mdiMagnify,
                mdiClockOutline,
                mdiPencilOutline
            }

        }
        
    },
})
</script>

<style scoped>

.illustration1 {
    position: absolute;
    inset-block-end: 0;
    inset-inline-end: 0;
}

.illustration2 {
    position: absolute;
    inset-block-start: 2rem;
    inset-inline-start: 2.5rem;
}
</style>
